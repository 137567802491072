const initialState = {
    addedImagesByCategory: [],
    currentPinnboardId: null,
    currentHouse: "Nürtingen"
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "DELETE_ITEM":
            var item = action.item;
            var {addedImagesByCategory} =  state;
            for(var i = 0; i < addedImagesByCategory.length; i++) {
                for(var x = 0; x < addedImagesByCategory[i].items.length; x++) {
                    if(addedImagesByCategory[i].items[x] == item || addedImagesByCategory[i].items[x].image == item.image ) {
                        addedImagesByCategory[i].items.splice(x, 1)
                        if(addedImagesByCategory[i].items.length == 0) {
                            addedImagesByCategory.splice(i, 1)
                        }
                    }
                }
            }
            return Object.assign({}, state, addedImagesByCategory)

        case "SET_HOUSE": {
            return {
                ...state,
                currentHouse: action.house
            }
        }
        case "UPDATE_DASHBOARD":
            window.selectedImage = undefined;
            window.selectedCat = undefined;
            return {
                ...state,
                currentPinnboardId: action.pinnboard.uuid,
                addedImagesByCategory: action.pinnboard.elements
            }
            break;
        case "CURRENT_DASHBOARD":
            return {
                ...state,
                currentPinnboardId: action.pinnboard.uuid,
                addedImagesByCategory: action.pinnboard.elements
            }
            break;
        case 'REGISTER_SUCCESS':
            return {
                ...state,
                currentPinnboardId: action.pinnboardId
            }
            break;
        case 'SAVE_IMAGE':
            const {image, category, note } = action;
            var alreadyAdded = false;
            var {addedImagesByCategory} =  state;
            for(var i = 0; i < addedImagesByCategory.length; i++) {
                if(addedImagesByCategory[i].category == category) {
                    alreadyAdded = true;
                    addedImagesByCategory[i].items.push({image: image, note: note});
                }
            }
            if(!alreadyAdded) {
                addedImagesByCategory.push(
                    {category: category, items: [{image: image, note: note}]}
                )
            }
            return {
                ...state,
                addedImagesByCategory: addedImagesByCategory
            }
        case "UPDATE_IMAGE":
            const imageOld = action.image;
            const imageNote = action.note;
            const categoryNew = action.category;
            var {addedImagesByCategory} =  state;
            for(var i = 0; i < addedImagesByCategory.length; i++) {
                for(var x = 0; x < addedImagesByCategory[i].items.length; x++) {
                    if(addedImagesByCategory[i].items[x] == imageOld || (addedImagesByCategory[i].items[x].image == imageOld)) {
                        addedImagesByCategory[i].items.splice(x, 1)
                        if(addedImagesByCategory[i].items.length == 0) {
                            addedImagesByCategory.splice(i, 1)
                            break;
                        }
                    }
                }
            }
            var alreadyAdded = false;
            for(var i = 0; i < addedImagesByCategory.length; i++) {
                if(addedImagesByCategory[i].category == categoryNew) {
                    alreadyAdded = true;
                    addedImagesByCategory[i].items.push({image: imageOld, note: imageNote});
                }
            }
            if(!alreadyAdded) {
                addedImagesByCategory.push(
                    {category: categoryNew, items: [{image: imageOld, note: imageNote}]}
                )
            }
            window.selectedImage = undefined;
            window.selectedCat = undefined;
            return {
                ...state,
                addedImagesByCategory: addedImagesByCategory
            }
        default:
            return state;
    }
};

