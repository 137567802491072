import Ink from 'react-ink'
import React from "react"
import {Actions, ActionsGroup, ActionsLabel, ActionsButton} from "framework7-react"

import Gallery from 'react-grid-gallery';
import apiAddress from "../../services/api/apiAddress";
const accentColor = "#CC9759";
var pressTimer = null;

const IMAGES =
    [{
        src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        isSelected: true,
        caption: "After Rain (Jeshu John - designerspics.com)"
    },
        {
            src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
            thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 212,
            tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
            caption: "Boats (Jeshu John - designerspics.com)"
        },

        {
            src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
            thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
            thumbnailWidth: 320,
            thumbnailHeight: 212
        }]
export default class ProductList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.mode,
            tileHeight: 0,
            selected: []
        }
    }

    refCallback = element => {
        console.log("onRef", element)
        this.tile = element;
        if (element) {
            this.setState({tileHeight: element.getBoundingClientRect().width});
            console.log();
        }
    };


    getImage(item) {
        var image = item;
        if (item && typeof item !== 'string') {
            image = item.image;
        }

        if(image.includes("data:image")) return image;
        return apiAddress.replace("/api", "") + "/pictures/"+ image;

    }

    onTouchStart(self, item) {
        console.log("onTouchStart", self, item);
       // this.state.selected.push(self);
        pressTimer = window.setTimeout(() => { this.onLongPressed(self)},500);
    }

    onLongPressed(image){
        console.log("onLongpress", image)
        this.state.selected = image;
        //this.setState(this.state);
        this.refs.deleteAction.open()
    }

    onTouchEnd(self, item) {
        console.log("onMouseUp", self)
        clearTimeout(pressTimer);
        // Clear timeout
        return false;
        var index = -1;
        // for(var i = 0; i < this.state.selected.length; i++) {
        //     if(this.state.selected[i] == self) {
        //         index = i;
        //         break;
        //     }
        // }

    }

    renderSecondImage(i, x, tileStyle, tileSize, imageStyle) {
        if(this.props.categories[i].items[x+1]) {
            tileStyle.background = "white"
            return (
                <div  key={i+x} onTouchStart={this.onTouchStart.bind(this, {cat: this.props.categories[i].category, image: this.props.categories[i].items[x+1]})}onTouchEnd={this.onTouchEnd.bind(this, this.props.categories[i].items[x+1])}  className={tileSize} ref={this.refCallback}  style={tileStyle}>
                    <Ink />
                    <img style={imageStyle}  src={this.props.categories[i].items[x+1] ? this.getImage(this.props.categories[i].items[x+1]): null} id={"kachel"} />
                </div>
            )
        } else {
             tileStyle.background = "transparent"
            return (
                <div  key={i+x}  className={tileSize} ref={this.refCallback}  style={tileStyle}>
                    <img style={imageStyle}  src={this.props.categories[i].items[x+1] ? this.getImage(this.props.categories[i].items[x+1]): null} id={"kachel"} />
                </div>
            )

        }

    }

    renderImages() {
        console.log("props", this.props)
        var views = [];
        var tileStyle = Object.assign({},{margin: 0,padding: 15,flexDirection: "column", background: "white", overflow: "hidden", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"})
        var imageStyle  ={width: "100%", objectFit: "cover"}
        if(this.props.mode == "grid") {
            tileStyle.height =this.state.tileHeight - 25;
            tileSize = "col-md-12 list-element"
            for(var i = 0; i < this.props.categories.length; i++) {
                console.log("render categories",this.props.categories[i])
                views.push(
                    <div style={{marginLeft: 15, paddingTop: 15, paddingBottom: 15,fontSize: 16, color: accentColor}}>{this.props.categories[i].category}</div>
                )
                for(var x = 0; x < this.props.categories[i].items.length; x = x +2 ) {
                    console.log("render Image")
                    views.push(
                        <div key={i+x} key={this.props.categories[i].category + "-"+ x} style={{width: "100%", margin: 0}} className={"row"}>
                            <div onTouchStart={this.onTouchStart.bind(this, {cat: this.props.categories[i].category, image: this.props.categories[i].items[x]})} onTouchEnd={this.onTouchEnd.bind(this, this.props.categories[i].items[x])}   className={tileSize} ref={this.refCallback}  style={tileStyle}>
                                <Ink />
                                <img style={imageStyle}   alt="test" src={this.getImage(this.props.categories[i].items[x])} id={"kachel"} />
                            </div>

                            {this.renderSecondImage(i, x, tileStyle, tileSize, imageStyle)}



                            {/*{this.props.mode == "grid" ? this.renderElement(tileStyle, addTileStyle, i++) : null}*/}
                        </div>
                    )
                }

            }
        } else {
            tileStyle.height =this.state.tileHeight - 25;
            tileSize = "col-md-12 list-element"
            for(var i = 0; i < this.props.categories.length; i++) {
                console.log("render categories",this.props.categories[i])
                views.push(
                    <div style={{marginLeft: 15, paddingTop: 15, paddingBottom: 15,fontSize: 16, color: accentColor}}>{this.props.categories[i].category}</div>
                )
                for(var x = 0; x < this.props.categories[i].items.length; x++ ) {
                    console.log("render Image")
                    views.push(
                        <div onTouchStart={this.onTouchStart.bind(this, this.props.categories[i].items[x])} onTouchEnd={this.onTouchEnd.bind(this, this.props.categories[i].items[x])}  key={this.props.categories[i].category + "-"+ x} style={{width: "100%", margin: 0}} className={"row list-element"}>
                            <div className={tileSize} ref={this.refCallback}  style={tileStyle}>
                                <Ink />
                                <img style={imageStyle}   alt="test" src={this.getImage(this.props.categories[i].items[x])} id={"kachel"} />
                            </div>

                            {/*{this.props.mode == "grid" ? this.renderElement(tileStyle, addTileStyle, i++) : null}*/}
                        </div>
                    )
                }

            }
        }

        var addTileStyle = Object.assign({}, tileStyle, {backgroundColor: "#E6E6E6"})
        var tileSize = "col-md-6";

        this.addTileAdded = false;
        /* views.push(
             <div>
             <div onClick={this.addImage} className={"col-md-6"} style={addTileStyle}>
                 <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
             </div>
                 {this.renderElement(tileStyle, addTileStyle, i++)}
             </div>
         )*/
        // if(!this.addTileAdded && this.props.addable) {
        //     views.push(
        //         <div style={{width: "100%", margin: 0}} className={"row"}>
        //             <div onClick={this.props.addImage} className={tileSize} style={addTileStyle}>
        //                 <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
        //             </div>
        //             {this.props.mode == "grid" ? <div style={{flex: 1}} className={"col-md-6"}></div> : null}
        //
        //
        //         </div>
        //     )
        // }
        return (

            views

        )
    }

    renderElement(tileStyle, addTileStyle, i) {
        var index = i + 1;
        console.log("index", index);
        var tileSize = "col-md-6";
        if(this.props.mode == "tile") tileSize = "col-md-12"
        if(this.props.images[index]) {
            return (
                <div onMouseDown={()=> {this.onMouseDown(this.props.images[index])}} onMouseUp={()=> {this.onMouseUp(this.props.images[index])}}   className={tileSize} ref={this.refCallback}  style={tileStyle}>
                    <img style={{width: "100%"}} alt="test" src={this.props.images[index]} id={"kachel"} />
                </div>
            )

        }
        // else if(this.props.mode == "grid" && this.props.addable) {
        //     this.addTileAdded = true;
        //     return (
        //         <div onClick={this.props.addImage.bind(this)} className={tileSize} style={addTileStyle}>
        //             <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
        //         </div>
        //     )
        //
        // }
    }


    render() {
        return (
            <div style={{paddingTop: 15}}>
                {this.renderImages()}
                <Actions ref="deleteAction">
                    <ActionsGroup>
                        <ActionsLabel>Optionen</ActionsLabel>
                        <ActionsButton onClick={() => this.props.onEdit(this.state.selected)}>Bearbeiten</ActionsButton>
                        <ActionsButton onClick={() => this.props.onDeleteElement(this.state.selected.image)}>Löschen</ActionsButton>
                        <ActionsButton color="red">Cancel</ActionsButton>
                    </ActionsGroup>
                </Actions>
            </div>
        )
    }

}

class ProductListItem extends React.Component {


    render() {
        return (
            <div>

            </div>
        )
    }
}