import React from 'react';
import { Page, Navbar, Link, Block, BlockTitle } from 'framework7-react';

export default ({ text, selected, onClick,color, containerStyle }) => {

    var style = {backgroundColor: color, borderRadius: 10, padding: 10}
    if(containerStyle) {
        style = Object.assign({}, style, containerStyle)
    }
    if(selected) {
        style.color = "white"
        style.backgroundColor = "#CC9759"
    }
    return(


    <div onClick={onClick} style={style}>
        {text}
    </div>

    )
}