import sampleImage from "../resources/sampleImage";

const initialState = {
    credentials: {
      email: "",
      token: "",
    },
    all: [
        /*{
            name: "Remi Tessier",
            email: "rete@adrodev.de",
            updatedAt: new Date(),
            items: [
                {
                    category: "Bad 1",
                    items: [
                        sampleImage,
                        sampleImage,
                        sampleImage
                    ]
                }
            ]
        },
        {
            name: "Throren Lederer",
            email: "thele@adrodev.de",
            updatedAt: new Date(),
            items: [
                {
                    category: "Bad 2",
                    items: [
                        sampleImage,
                        sampleImage,
                    ]
                }
            ]
        },
        {
            name: "Tanja Springer",
            email: "springer@agenturid.de",
            updatedAt: new Date(),
            items: [
                {
                    category: "Bad 3",
                    items: [
                        sampleImage,
                    ]
                }
            ]
        },*/
    ]
}

export default (state = initialState, action) => {
    switch (action.type) {
        // case "STAFF_LOGIN_SUCCESS":
        //     return {
        //         ...state,
        //         credentials: {email: action.email, token: action.token}
        //     }
        //     break;
        case "CUSTOMER_PINNBOARDS":
            console.log("action", action)
            var all = [];
            for(var i = 0; i < action.pinnboards.length; i++) {
                var item = action.pinnboards[i];
                all.push(
                    {
                        name: item.customer.name,
                        uuid: item.uuid,
                        email: item.customer.email,
                        updatedAt: item.updatedAt ? new Date(item.updatedAt) : new Date(),
                        items: item.elements,
                        note: item.note || ""
                    }
                )
            }
            return Object.assign({}, state, {all: all})
            break;
        default:
            return state;
    }
};

