
import React from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    Searchbar,
    PhotoBrowser
} from 'framework7-react';
import {openNamePopup, openPopup} from "../../actions/PopupActions";
import {goToAbout} from "../../actions/AboutActions";
import HomePage from "./HomePage";
import {goToDefaultRoute} from "../../actions/DefaultRouteActions";
import {openPanelLeft} from "../../actions/PanelLeftActions";
import {openPanelRight} from "../../actions/PanelRightActions";
import {connect} from "react-redux";
import {goToDynamicRoute} from "../../actions/DynamicRouteActions";
import {goToForm} from "../../actions/FormActions";
import {openLogin} from "../../actions/LoginActions";
import sampleImage from "../../resources/sampleImage"
import elementWhite from "../../resources/elements_white.png"
import ProductList from "../elements/ProductList";
import PinboardItemList from "../elements/PinboardItemList";
import Https from "../../services/api/apiClient";
import {goBack} from "../../services/Navigator";
import {getPinnboardsByHouse} from "../../actions/DashboardActions";
const background = "https://www.elements-show.de/sites/default/files/styles/crop_sr_958_height_min/public/media-plus/hg-elements-traumbad-05_19_1.jpg?itok=8VDOwjJV"


const accentColor = "#CC9759";
class CustomerView extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            email: localStorage.getItem('customer-email'),
            token: localStorage.getItem('customer-token'),
            customer: window.currentCustomer
        }

        console.log("hallo", this.state);
    }

    reloadData = () => {
        try {
            // if(this.props.customer.credentials.email && this.props.customer.credentials.token) {
                this.props.getPinnboardsByHouse(false, this.state.email, this.state.token, (success) => {
                    console.log(success)
                    this.setState({loginOpen: false})
                }, (err) => {
                    console.log(err)
                    alert("login failed")
                })
            // } else {
            //     this.setState({loginOpened: true})
            // }
        }catch(err) {

        }

    }




    render() {
        return(
            <Page>
                <Navbar title="Kunden Pinnwand" backLinkUrl={"/"} backLink="Back">
                    <NavRight>
                        <Link icon="delete" panelOpen="right"></Link>
                    </NavRight>
                </Navbar>
                <div style={{textAlign: "center",backgroundColor: "#1A1A1A"}}>
                    <img style={{width: 70, paddingTop: 10, paddingBottom: 10}} src={elementWhite} />
                </div>
                <div style={{display: "flex", flexDirection: "column",paddingTop: 8,paddingLeft:10, paddingRight:10, paddingBottom: 8,justifyContent: "center", backgroundColor: "#E6E6E6"}}>
                    <span style={{color: "#1A1A1A",fontWeight: 500, fontSize: "1.3em"}}>{this.state.customer.name}</span>
                    <span style={{color: "#1A1A1A",fontWeight: 500,fontSize: "1.3em"}}>{this.state.customer.email}</span>
                </div>
                <div>
                    <textarea style={{border: "0.5px solid #F19B38", padding: 5, width: "100%"}}  onChange={(e) => this.setState({customer: {...this.state.customer, note: e.target.value}})} placeholder={"Notiz"} value={this.state.customer.note} type={"text"} />
                    <div style={{margin: "auto",marginTop: 10, marginBottom: 10, maxWidth: 100}}>
                    <Button onClick={() => {
                        Https.put("/pinnboard/"+ this.state.customer.uuid +"/note", {note: this.state.customer.note}).then((res) => {
                            this.reloadData();
                        })
                    }}>Speichern</Button>
                    </div>
                </div>
                {this.state.customer ? <PinboardItemList
                    categories={this.state.customer.items}
                    addable={false}
                    mode={"tile"} /> : null }
                    <div style={{margin: "auto",marginTop: 10, marginBottom: 10, maxWidth: 100}}>
                        <Button fill color="red" onClick={ async () => {
                            var confirm = window.confirm("Soll die Pinnwand wirklich gelöscht werden?")
                            if(confirm) {
                                Https.post("/pinnboard/"+ this.state.customer.uuid + "/delete" , {note: this.state.customer.note}).then((res) => {
                                    goBack(this);
                                    this.reloadData();


                                })
                            }

                        }}>Löschen</Button>
                    </div>



            </Page>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGoToAbout: () => dispatch(goToAbout()),
        onGoToForm: () => dispatch(goToForm()),
        onGoToDynamicRoute: () => dispatch(goToDynamicRoute()),
        onGoToDefaultRoute: () => dispatch(goToDefaultRoute()),
        onOpenLeftPanel: () => dispatch(openPanelLeft()),
        onOpenRightPanel: () => dispatch(openPanelRight()),
        onOpenPopup: () => dispatch(openPopup()),
        openNamePopup: () => dispatch(openNamePopup()),
        onOpenLoginScreen: () => dispatch(openLogin()),
        getPinnboardsByHouse: (save,email, token, success, error) => {dispatch(getPinnboardsByHouse(save, email, token, success, error))}

    };
};

const mapStateToProps = state => ({
    customers: state.customer.all,
    customer: state.customer

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerView);
