
import React from 'react';
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    PhotoBrowser,
    Fab,
    Icon,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton
} from 'framework7-react';
import {openNamePopup, openPopup} from "../../actions/PopupActions";
import {fetchPinnboard, shareWithHouse, deleteItem} from "../../actions/DashboardActions";

import {connect} from "react-redux";
import {goToDynamicRoute} from "../../actions/DynamicRouteActions";
import {goToForm} from "../../actions/FormActions";
import {openLogin} from "../../actions/LoginActions";
import elementWhite from "../../resources/elements_white.png"
import ProductList from "../elements/ProductList";
import { goBack, navigateTo, showAlert, showConfirm } from '../../services/Navigator';
import Resizer from 'react-image-file-resizer';

const background = "https://www.elements-show.de/sites/default/files/styles/crop_sr_958_height_min/public/media-plus/hg-elements-traumbad-05_19_1.jpg?itok=8VDOwjJV"

var pressTimer = null;
const accentColor = "#CC9759";
class Dashboard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            files: [],
            images: [],
            tileHeight: 0,
            selectedTileMode:1,
            selected: [],
            mode: "grid"
        }

        this.onChange = this.onChange.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onFinishWork = this.onFinishWork.bind(this);
        this.shareWith = this.shareWith.bind(this);
        this.onLongPressed = this.onLongPressed.bind(this)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)
        this.onImageResized = this.onImageResized.bind(this)



    }

    componentWillMount() {
        var splittedLink = window.location.href.split("/");
        var pinnboardID = splittedLink[splittedLink.length -1];
        if(pinnboardID.length == 37) {
            pinnboardID = pinnboardID.substring(0, 36);
        }
        if(pinnboardID.length == 36) {
            this.props.fetchPinnboard(pinnboardID,(result) => {

            }, (err) => {

            });
        } else {
            //this.$f7router.navigate("/");
        }
    }

    componentWillUnmount = () => {
        const self = this;
        // Destroy toasts when page removed
        if (self.toastBottom) self.toastBottom.destroy();
    }

    onChange(e) {
        //this.state.files.push(e.target.files[0]);
        var reader = new FileReader();
        // reader.onload = this.onImageLoaded;


        if(e.target.files.length > 0) {
            this.onImageLoaded(e.target.files[0]);
            // reader.readAsDataURL(e.target.files[0]);
            // this.setState(this.state.files);
        }

    }

    refCallback = element => {
        console.log("onRef", element)
        this.tile = element;
        if (element) {
            this.setState({tileHeight: element.getBoundingClientRect().width});
           console.log();
        }
    };

    onMouseUp(image) {
        console.log("onMouseUp", image)
        clearTimeout(pressTimer);
        // Clear timeout
        return false;
    }


    onMouseDown(image) {
        console.log("onMouseDown", image)
        pressTimer = window.setTimeout(() => { this.onLongPressed(image)},1000);
    }

    onLongPressed(image){
        console.log("onLongpress", image)
        this.state.selected.push(image)
        this.setState(this.state)
    }

    componentDidUpdate() {
        console.log("did uopdate")
        if(this.tile) {
           if(this.state.tileHeight != this.tile.getBoundingClientRect().width) {
               this.setState({tileHeight: this.tile.getBoundingClientRect().width});
           }
        }
    }



    renderImages() {
        var views = [];
        var tileStyle = Object.assign({},{margin: 25,padding: 0, overflow: "hidden", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"})
        var imageStyle  ={width: "100%", objectFit: "cover"}
        if(this.state.mode == "tile") {
            tileStyle.height =this.state.tileHeight - 25;
            tileSize = "col-md-12"
        } else {
            tileStyle.height = this.state.tileHeight
            imageStyle.height = "100%"
        }

        var addTileStyle = Object.assign({}, tileStyle, {backgroundColor: "#E6E6E6"})
        var tileSize = "col-md-6";

        this.addTileAdded = false;
        for(var i = 0; i < this.props.files.length; i++) {
            views.push(
                <div  onTouchStart={()=> {this.onMouseDown(this.state.images[i])}} onTouchEnd={()=> {this.onMouseUp(this.state.images[i])}} style={{width: "100%", margin: 0}} className={"row"}>
            <div  onClick={() => this.standaloneDark.open()}   className={tileSize} ref={this.refCallback}  style={tileStyle}>
                <img style={imageStyle}   alt="test" src={this.state.images[i]} id={"kachel"} />
            </div>
                    {this.state.mode == "grid" ? this.renderElement(tileStyle, addTileStyle, i++) : null}
                </div>
            )
        }
        console.log("views", views);
       /* views.push(
            <div>
            <div onClick={this.addImage} className={"col-md-6"} style={addTileStyle}>
                <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
            </div>
                {this.renderElement(tileStyle, addTileStyle, i++)}
            </div>
        )*/
        if(!this.addTileAdded) {
            views.push(
                <div style={{width: "100%", margin: 0}} className={"row"}>
                    <div onClick={this.addImage} className={tileSize} style={addTileStyle}>
                        <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
                    </div>
                    {this.state.mode == "grid" ? <div style={{flex: 1}} className={"col-md-6"}></div> : null}


                </div>
            )
        }
        return (

                views

            )
    }

    renderElement(tileStyle, addTileStyle, i) {
        var index = i + 1;
        console.log("index", index);
        var tileSize = "col-md-6";
        if(this.state.mode == "tile") tileSize = "col-md-12"
        if(this.state.images[index]) {
            return (
                <div onMouseDown={()=> {this.onMouseDown(this.state.images[index])}} onMouseUp={()=> {this.onMouseUp(this.state.images[index])}}   onClick={() => this.standaloneDark.open()} className={tileSize} ref={this.refCallback}  style={tileStyle}>
                    <img style={{width: "100%"}} alt="test" src={this.state.images[index]} id={"kachel"} />
                </div>
                )

        } else if(this.state.mode == "grid") {
            this.addTileAdded = true;
            return (
                <div onClick={this.addImage} className={tileSize} style={addTileStyle}>
                    <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
                </div>
                )

        }
    }

    onImageLoaded(e) {
        Resizer.imageFileResizer(
            e,
            300,
            300,
            'PNG',
            100,
            0,
            uri => {
                this.onImageResized(uri);
            },
            'base64'
        );

    }

    onImageResized(image) {
        //this.state.images.push(image);
        window.selectedImage = image;
        navigateTo(this, "/popup/");

        //this.setState(this.state);
    }

    onDeleteElement(el) {
        console.log(el);
        this.props.deleteItem(el);
    }

    onEdit(el) {
        console.log("onEdit", el);
        window.selectedImage = el.image;
        window.selectedCat = el.cat;
        window.uuid = el.uuid;
        navigateTo(this, "/popup/");
    }



    renderContent() {
        if(this.props.files.length > 0) {
            return (
                <ProductList
                    categories={this.props.files}
                    images={this.state.images}
                    addable={true}
                    mode={this.state.mode}
                    addImage={this.addImage.bind(this)}
                    onMouseUp={this.onMouseUp.bind(this)}
                    onMouseDown={this.onMouseDown.bind(this)}
                    onDeleteElement={this.onDeleteElement.bind(this)}
                    onEdit={this.onEdit.bind(this)}
                />
                )
        }
        return this.renderPlaceholder();
    }

    addImage(){
        window.selectedCat = null;
        window.selectedImage = null;
        console.log("addImage",  document.getElementById("image-selector") );
        document.getElementById("image-selector").click()
    }

    renderPlaceholder() {
        return (
            <div style={{backgroundColor: "#F2F2F2"}}>
                <div style={{padding: 30, height: "100%"}}>
                    <div onClick={() => {this.addImage()}} style={{ position: "relative", width: "100%",height: 220,justifyContent: "center", alignItems: "center",display: "flex",textAlign: "center"}}>
                        <div style={{width: "100%", height: "100%",position: "absolute", top: 0,opacity: 0.7, backgroundColor: "black" }}> </div>
                        <div style={{width: "100%", height: "100%",position: "absolute", top: 0,opacity: 0.8, backgroundSize: "contain",backgroundRepeat: "no-repeat", backgroundImage: "url(" + background + ")" }}> </div>
                        <i style={{zIndex: 30000, flex: 1,color: "white", padding: 5, fontSize: 75}} class="material-icons">add</i>
                    </div>
                </div>
            </div>
        )
    }

    onSyncConfirmed() {
        this.props.shareWithHouse(() => {
            const self = this;
            // Create toast
            if (!self.toastBottom) {
                self.toastBottom = self.$f7.toast.create({
                    text: 'Ihre Pinnwand wurde erfolgreich geteilt.',
                    closeTimeout: 2000,
                });
            }
            // Open it
            self.toastBottom.open();
        }, () =>{
            const self = this;
            // Create toast
            if (!self.toastBottom) {
                self.toastBottom = self.$f7.toast.create({
                    text: 'Leider ist etwas schief gegangen.',
                    closeTimeout: 2000,
                });
            }
            // Open it
            self.toastBottom.open();
        });
    }

    onFinishWork() {
        this.$f7.dialog.confirm("Möchten Sie Ihre Pinnwand jetzt mit einem Elements Mitarbeiter teilen?", "Pinnwand teilen",this.onSyncConfirmed.bind(this), () => {

        })
       // this.props.showConfirm( "Möchten Sie Ihre Pinnwand jetzt mit einem Elements Mitarbeiter teilen?", "Pinnwand teilen");
       // this.props.openNamePopup()
    }

    renderHeaderContent() {
        if(this.props.files.length > 0) {
            return (
                <div onClick={this.onFinishWork} style={{marginTop: 30, backgroundColor: "#CC9759", borderRadius: 20, display: "flex", flexDirection: "row", alignItems: "center",  paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>
                    <i style={{color: "white"}} class="material-icons">people</i>
                    <span style={{paddingLeft: 5,color: "white"}}>MIT ELEMENTS TEILEN</span>
                </div>
            )
        } else {
            return (
                <div style={{position: "relative"}}>
                    {/*<div style={{width: "100%", height: "100%",position: "absolute", top: 0,opacity: 0.5, backgroundSize: "contain",backgroundRepeat: "no-repeat", backgroundImage: "url(" + background + ")" }}> </div>*/}
                    <div>
                    <p>Herzlich willkommen bei ELEMENTS. Auf dieser Pinnwand können Sie alle Ideen rund um Ihr neues Bad verwalten. Um Ihre Ideen zu organisieren, können Sie Pinnwand Ordner anlegen. Natürlich können die Pinnwände auch geteilt werden – mit Freunden, der Familie oder den ELEMENTS Badberatern, um weitere Informationen zu den Produkten zu erhalten oder zur Vorbereitung Ihres Planungstermins.</p>
                    {/*<p>Sie können diese im Anschluss mit ELEMENTS teilen, um weitere Informationen zu erhalten oder um ein Angebot von einem unserer Mitarbeiter zu erhalten. Sie können Ihre Pinnwand auch mit Ihren Kontakten teilen.</p>*/}
                    </div>
                </div>
            )
        }
    }

    onShare() {
        console.log("onShare", navigator)
        if (navigator.share) {
            navigator.share({
                title: 'Web Fundamentals',
                text: 'Check out Web Fundamentals — it rocks!',
                url: 'https://developers.google.com/web',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            this.refs.actionsOneGroup.open()
        }
    }

     iosCopyToClipboard(el) {
        var oldContentEditable = el.contentEditable,
            oldReadOnly = el.readOnly,
            range = document.createRange();

        el.contentEditable = true;
        el.readOnly = false;
        range.selectNodeContents(el);

        var s = window.getSelection();
        s.removeAllRanges();
        s.addRange(range);

        el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

        el.contentEditable = oldContentEditable;
        el.readOnly = oldReadOnly;

        document.execCommand('copy');
    }

    shareWith(shareType) {
        var title = "Meine ELEMENTS Pinnwand";

        var url = window.location.href;
        if(url.includes("#")) {
            url = url.split("#")[0];
        }
        var text = "Unter dem folgenden Link kannst du meine Impressionen aus der Badausstellung anschauen. "+  url;
        switch(shareType) {
            case "Whatsapp":
                text = encodeURI(text)
                if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    window.location = "whatsapp://send?text="+text;
                    break;
                }

                window.location = "https://wa.me/?text="+text;
                break;
            case "Email":
                text = encodeURI(text)
                window.location = `mailto:?subject=${title}&body=${text}`;
                break;
            case "Clipboard":

                const el = document.createElement('textarea');
                el.value = text;
                document.body.appendChild(el);
                var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                if(iOS) {
                    this.iosCopyToClipboard(el);
                } else {
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);

                }
                const self = this;
                self.toastBottom = self.$f7.toast.create({
                    text: 'Zur Zwischenablage hinzugefügt',
                    closeTimeout: 2000,
                });
                self.toastBottom.open();


                break;
        }
    }

    getModeColor(mode) {
        if(this.state.mode == mode) return accentColor;
        return "#1A1A1A"
    }

    render() {
        return(
            <Page>
                <div style={{display: "flex", justifyContent: "center",backgroundColor: "#1A1A1A",paddingTop: 10,paddingBottom: 40, paddingLeft: 10, paddingRight: 10}}>
                    <div style={{width: "100%",textAlign: "center",  display: "flex",color:"white", justifyContent: "center", flexDirection: "column", alignItems:"center"}}>
                        <img  style={{width: 70, paddingBottom: 10}} src={elementWhite} />
                        <h4>Mein Bad</h4>
                        {this.renderHeaderContent()}
                    </div>
                </div>
                <div style={{display: "flex",paddingTop: 8,paddingLeft:10, paddingRight:10, paddingBottom: 8,textAlign: "center",justifyContent: "center", backgroundColor: "#E6E6E6"}}>
                    <i onClick={() => {this.setState({mode: "grid"})}} style={{flex: 1,color: this.getModeColor("grid"),padding: 5, fontSize: "1.5rem"}} class="material-icons">view_module</i>
                    <i onClick={() => {this.setState({mode: "tile"})}} style={{flex: 1,color: this.getModeColor("tile"),padding: 5, fontSize: "1.5rem"}}class="material-icons">view_day</i>
                    <i onClick={this.onShare.bind(this)} style={{flex: 1,color: "#1A1A1A",padding: 5, fontSize: "1.5rem"}}class="material-icons">send</i>
                </div>
                {this.renderContent()}
                <input onChange={this.onChange} style={{display: "none"}} id={"image-selector"} type="file" name="myImage" accept="image/*" />
                {/*<PhotoBrowser photos={this.state.images} theme="dark" type="popup" ref={(el) => {this.standaloneDark = el}} />*/}
                <Actions ref="actionsOneGroup">
                    <ActionsGroup>
                        <ActionsLabel>Teilen</ActionsLabel>
                        <ActionsButton onClick={() => this.shareWith("Email")} >Email</ActionsButton>
                        <ActionsButton onClick={() => this.shareWith("Whatsapp")}>Whatsapp</ActionsButton>
                        <ActionsButton onClick={() => this.shareWith("Clipboard")}>Link kopieren</ActionsButton>
                        <ActionsButton color="red">Cancel</ActionsButton>
                    </ActionsGroup>
                </Actions>
                <input style={{display: "none"}} type="text" value="Hello World" id="myInput"/>
                <Fab onClick={this.addImage.bind(this)} position="right-bottom" slot="fixed" color="orange">
                    <Icon ios="f7:add" aurora="f7:add" md="material:add"></Icon>
                    <Icon ios="f7:close" aurora="f7:close" md="material:close"></Icon>
                </Fab>
            </Page>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {


        openNamePopup: () => dispatch(openNamePopup()),
        onOpenLoginScreen: () => dispatch(openLogin()),
        fetchPinnboard: (pinnboardId) => dispatch(fetchPinnboard(pinnboardId)),
        shareWithHouse: (success, error) => dispatch(shareWithHouse(success, error)),
        showConfirm: (text, title) => dispatch(showConfirm(text, title)),
        navigateTo: (url) => dispatch(navigateTo(url)),
        deleteItem: (item) => dispatch(deleteItem(item))
    };
};

const mapStateToProps = (state) => {
    return {
        files: state.dashboard.addedImagesByCategory,
        dashboard: state.dashboard,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
