
import React, {Fragment} from "react"
import apiAddress from "../../services/api/apiAddress";


export default class ProductList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: props.mode,
            tileHeight: 0
        }
    }

    refCallback = element => {
        console.log("onRef", element)
        this.tile = element;
        if (element) {
            this.setState({tileHeight: element.getBoundingClientRect().width});
            console.log();
        }
    };

    getImage(item) {
        var image = item;
        if (item && typeof item !== 'string') {
            image = item.image;
        }
        return apiAddress.replace("/api", "") + "/pictures/"+ image
    }

    renderImages() {
        var views = [];
        var tileStyle = Object.assign({},{padding: 25,flexDirection: "column", padding: 0, overflow: "hidden", flex: 1, display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"})
        var imageStyle  ={width: "100%", objectFit: "cover"}
        if(this.props.mode == "tile") {
            tileStyle.height =this.state.tileHeight - 25;
            tileSize = "col-md-12"
        } else {
            tileStyle.height = this.state.tileHeight
            imageStyle.height = "100%"
        }

        var addTileStyle = Object.assign({}, tileStyle, {backgroundColor: "#E6E6E6"})
        var tileSize = "col-md-6";

        this.addTileAdded = false;
        for(var i =0; i < this.props.categories.length; i++) {
            views.push(
                <div style={{paddingLeft: 25, marginTop: 20, color: "#CC9759", fontSize: "1.5em"}}>{this.props.categories[i].category}</div>
            )
            for(var x = 0; x < this.props.categories[i].items.length; x++) {
                var item = this.props.categories[i].items[x];
                views.push(
                    <div  style={{width: "100%", margin: 0}} className={"column"}>
                        <div     className={tileSize} ref={this.refCallback}  style={tileStyle}>
                            <img style={imageStyle}   alt="test" src={this.getImage(item)} id={"kachel"} />

                        </div>
                        <div style={{paddingTop: 10, textAlign: "center"}}>{item.note}</div>

                        {this.props.mode == "grid" ? this.renderElement(tileStyle, addTileStyle, i++) : null}
                    </div>
                )
            }

        }
        console.log("views", views);
        /* views.push(
             <div>
             <div onClick={this.addImage} className={"col-md-6"} style={addTileStyle}>
                 <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
             </div>
                 {this.renderElement(tileStyle, addTileStyle, i++)}
             </div>
         )*/
        if(!this.addTileAdded && this.props.addable) {
            views.push(
                <div style={{width: "100%", margin: 0}} className={"row"}>
                    <div onClick={this.props.addImage} className={tileSize} style={addTileStyle}>
                        <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
                    </div>
                    {this.props.mode == "grid" ? <div style={{flex: 1}} className={"col-md-6"}></div> : null}


                </div>
            )
        }
        return (

            views

        )
    }

    renderElement(tileStyle, addTileStyle, i, items) {
        var index = i + 1;
        console.log("index", index);
        var tileSize = "col-md-6";
        var item = items[index];
        if(this.props.mode == "tile") tileSize = "col-md-12"
        if(item) {
            return (
                <Fragment>
                <div   className={tileSize} ref={this.refCallback}  style={tileStyle}>
                    <img style={{width: "100%"}} alt="test" src={this.getImage(item)} id={"kachel"} />
                </div>
                <div  style={{paddingTop: 10, textAlign: "center"}}>{item.note}</div>
                </Fragment>
            )

        } else if(this.props.mode == "grid" && this.props.addable) {
            this.addTileAdded = true;
            return (
                <div onClick={this.props.addImage} className={tileSize} style={addTileStyle}>
                    <i style={{flex: 1,color: "white",padding: 5, fontSize: 75}} class="material-icons">add</i>
                </div>
            )

        }
    }


    render() {
        return (
            <div>
                {this.renderImages()}
            </div>
        )
    }

}

class ProductListItem extends React.Component {


    render() {
        return (
            <div>

            </div>
        )
    }
}