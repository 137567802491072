import { createStore, applyMiddleware, compose, combineReducers, createFilter } from 'redux';
import { Framework7StateKernel, framework7Reducer, syncFramework7WithStore } from 'framework7-redux';
import thunk from 'redux-thunk';

import loginReducer from './reducers/LoginReducer';
import formReducer from './reducers/FormReducer';
import dashboardReducer from './reducers/DashboardReducer';
import customerReducer from './reducers/CustomerReducer';
import credentialReducer from './reducers/CredentialsReducer';
import {persistCombineReducers, persistStore } from "redux-persist";

import storage from "redux-persist/es/storage";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['login']
};

//export const stateKernel = new Framework7StateKernel();

/* eslint-disable */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
const persistedReducer = persistCombineReducers(persistConfig, {
    login: loginReducer,
    form: formReducer,
    dashboard: dashboardReducer,
    customer: customerReducer,

});


export const store = createStore(
  persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);


//syncFramework7WithStore(store, stateKernel);

