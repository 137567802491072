import HomeContainer from './containers/HomeContainer';
import AboutContainer from './containers/AboutContainer';
import FormContainer from './containers/FormContainer';
import DynamicRouteContainer from './containers/DynamicRouteContainer';
import NotFoundContainer from './containers/DefaultRouteContainer';
import PanelLeftContainer from './containers/PanelLeftContainer';
import PanelRightContainer from './containers/PanelRightContainer';
import EntryPage from './containers/EntryPage';
import LoginContainer from './containers/LoginContainer';
import PopupContainer from './containers/PopupContainer';
import Dashboard from "./components/pages/Dashboard";
import SetNamePopupContainer from "./containers/SetNamePopupContainer";
import CustomerOverview from "./components/pages/CustomerOverview";
import CustomerView from "./components/pages/CustomerView";

export default [

    {
        path: '/',
        component: EntryPage
    },
    {
        path: '/customer',
        component: CustomerOverview,
    },
    {
        path: '/customer/:pinboardID',
        component: CustomerView,
    },
    {
        path: '/pinnboard/:pinboardId',
        component: Dashboard

    },
    {
        path: '/home',
        component: HomeContainer,
    },
  {
    path: '/panel-left/',
    panel: {
      component: PanelLeftContainer,
    },
  },
  {
    path: '/panel-right/',
    panel: {
      component: PanelRightContainer,
    },
  },
  {
    path: '/about/',
    component: AboutContainer,
  },
  {
    path: '/form/',
    component: FormContainer,
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRouteContainer,
  },
  {
    path: '/login/',
      component: LoginContainer
  },
  {
    path: '/popup/',
    popup: {
      component: PopupContainer
    }
  },
    {
        path: '/popup/name',
        popup: {
            component: SetNamePopupContainer
        }
    },
  {
    path: '(.*)',
    component: EntryPage,
  },
];
