
import Https from "../services/api/apiClient";
import {navigateTo, showAlert} from "framework7-redux";


export function addImage  (image, category, note) {
    return function (dispatch, getState) {
        dispatch(addImageToStore(image, category, note))
        setTimeout(() => {
            dispatch(shareWithHouse())
        }, 1000)
    }
}

function addImageToStore(image, category, note) {
    return function (dispatch, getState) {
        dispatch({
            type: 'SAVE_IMAGE',
            image: image,
            category, category,
            note: note
        })
    }
}


export function deleteItem  (item) {
    return function (dispatch, getState) {
        dispatch(
            {type: "DELETE_ITEM", item: item}
        )
        setTimeout(() => {
            dispatch(shareWithHouse())
        }, 1000)
    }
}
export function updateImage  (uuid, image, category, note) {
    return function (dispatch, getState) {
        dispatch(
            {
                type: 'UPDATE_IMAGE',
                image: image,
                category, category,
                note: note,
                uuid: uuid,
            }
        )
        setTimeout(() => {
            dispatch(shareWithHouse())
        }, 1000)
    }
}

export function shareWithStaff(name, successCB, errorCB) {
    return function (dispatch, getState) {
        console.log("share items", getState().dashboard.addedImagesByCategory);


        setTimeout(() => {
            successCB();
        }, 2000);
        // TODO API Call
    }
}

export function setCurrentHouse(name) {
    return function (dispatch, getState) {
        dispatch({
            type: "SET_HOUSE",
            house: name
        })
    }
}

export function fetchPinnboard(pinnboardId, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.get("/pinnboard/"+pinnboardId).then((res) => {
            dispatch({
                type: "CURRENT_DASHBOARD",
                pinnboard: res.data.pinnboard
            })
        }).catch((err) => {
        })
        console.log("share items", getState().dashboard.addedImagesByCategory);
    }
}

export function shareWithHouse(successCB, errorCB) {
    return function (dispatch, getState) {
        console.log("share items", getState().dashboard.addedImagesByCategory);
        console.log("share items", getState().dashboard.currentPinnboardId);
        console.log("share items", getState().dashboard.currentHouse);
        Https.post("/pinnboard/shareWithHouse", {elements: getState().dashboard.addedImagesByCategory, pinnboardUuid: getState().dashboard.currentPinnboardId, house: getState().dashboard.currentHouse}).then((res) => {
            if(successCB) successCB(res.data);
            dispatch({
                type: "UPDATE_DASHBOARD",
                pinnboard: res.data.pinnboard
            })
            // dispatch(navigateTo("/pinnboard/"+res.data.pinnboardId));
            // dispatch({
            //     type: "REGISTER_SUCCESS",
            //     pinnboardId: res.data.pinnboardId
            // })
            //dispatch(closeLogin());
        }).catch((err) => {
            dispatch(showAlert('Ein Fehler beim Teilen Ihrer Pinnwand ist aufgetreten.', 'Hoppla!'));
            if(errorCB) errorCB(err);
        });

    }
}

export function getPinnboardsByHouse(save, email, token, successCB, errorCB) {
    return function (dispatch, getState) {
        Https.post("/pinnboard/allByStaffMember", {email: email, token: token}).then((res) => {
            if(successCB) successCB(res.data);
            // dispatch(navigateTo("/pinnboard/"+res.data.pinnboardId));
            if(save) {

                localStorage.setItem('customer-email', email);
                localStorage.setItem('customer-token', token);
            }

            dispatch({
                type: "STAFF_LOGIN_SUCCESS",
                email: email,
                token: token
            })

            dispatch({
                type: "CUSTOMER_PINNBOARDS",
                pinnboards: res.data.allPinnboards
            })
            //dispatch(closeLogin());
        }).catch((err) => {
            dispatch(showAlert('Ein Fehler beim Teilen Ihrer Pinnwand ist aufgetreten.', 'Hoppla!'));
            if(errorCB) errorCB(err);
        });

    }
}