import { connect } from 'react-redux'
import React from 'react';
import { View, Page, LoginScreen, LoginScreenTitle, List, ListItem, Label, Input, ListButton, BlockFooter } from 'framework7-react';
import { usernameUpdated, passwordUpdated,phoneNumberUpdated, login } from '../actions/LoginActions';
import { getUsername, getPassword } from '../selectors/LoginSelectors';
import Logo from "../resources/Download.png"
import {navigateTo} from "../services/Navigator";




const mapStateToProps = (state) => {
  return {
    username: getUsername(state),
    password: getPassword(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUsernameUpdated: (username) => dispatch(usernameUpdated(username)),
    onPasswordUpdated: (password) => dispatch(passwordUpdated(password)),
    onPhoneNumberUpdated: (number) => dispatch(phoneNumberUpdated(number)),
    onLogin: (email, name, number, onSuccess, onFailure) => dispatch(login(email, name, number, onSuccess, onFailure))
  };
};

class LoginContainer extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            name: "",
            email: "",
            phoneNumber: "",
            accepted: false
        }
    }


    onTextFieldChanged(name, value) {
        this.state[name] = value;
        this.setState(this.state);
    }

    onLogin() {
        if(this.state.accepted) {
            this.props.onLogin(this.state.email, this.state.name,this.state.phoneNumber, (success) => {
                window.history.pushState('page2', 'Title', "/pinnboard/"+success.pinnboardId);
                navigateTo(this,"/pinnboard/"+success.pinnboardId);
            }, (err) => {
                console.log(err)
                alert("login failed")
            })
        } else {
            alert("Bitte akzeptieren Sie die Nutzungsbedingungen")
        }


    }

    render() {


        return(
            <Page id="login-screen">
                <View>
                    <Page loginScreen>
                        <List form>
                            <ListItem>
                                <div style={{width: "100%", justifyContent: "center", alignCenter: "center", display: "flex"}}>
                                    <img src={Logo} />
                                </div>
                            </ListItem>
                            <ListItem>
                                <Input
                                    name="name"
                                    placeholder="Name"
                                    type="text"
                                    onChange={({ target }) => this.onTextFieldChanged(target.name, target.value)}
                                    value={this.state.username}
                                />
                            </ListItem>
                            <ListItem>
                                <Input
                                    name="email"
                                    placeholder="Email Adresse"
                                    type="text"
                                    onChange={({ target }) => this.onTextFieldChanged(target.name, target.value)}
                                    value={this.state.email}
                                />
                            </ListItem>
                            <ListItem>
                                <Input
                                    name="phoneNumber"
                                    placeholder="Telefonnummer (Optional)"
                                    type="text"
                                    onChange={({ target }) => this.onTextFieldChanged(target.name, target.value)}
                                    value={this.state.phoneNumber}
                                />
                            </ListItem>
                            <ListItem
                                key={"asda"}
                                checkbox
                                name="my-checkbox"
                                onClick={() => {this.setState({accepted: !this.state.accepted})}}
                            ><div>Ich akzeptiere die Elements-Pinnwand <a style={{color: "blue", textDecoration: "underline"}} target={"_blank"} onClick={() => window.location.href= "https://www.elements-show.de/datenschutz"}>Nutzungsbedingungen und Datenschutzrichtlinien</a></div>
                            </ListItem>

                        </List>
                        <List>
                            <ListButton title="Los geht's" onClick={this.onLogin.bind(this)}></ListButton>
                            <BlockFooter>
                                <p>Registrieren Sie sich mit Ihrer Email Adresse um Ihre individuelle ELEMENTS Pinnwand zu erstellen</p>
                            </BlockFooter>
                        </List>
                    </Page>
                </View>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
