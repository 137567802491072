


export default class Navigator {


}

export const navigateTo = function(self, path) {
    self.$f7router.navigate(path);
}



export const goBack = function(self) {
    self.$f7router.back();
}

export const showAlert = function(self) {
    self.$f7router.showAlert();
}

export const showConfirm = function(self) {
    self.$f7router.showAlert();
}

