
import React from 'react';
import Chip from "../elements/Chips"
import { Popup,Fab, Icon, View, Page, Navbar, NavRight, Link, Block } from 'framework7-react';
import apiAddress from "../../services/api/apiAddress";
export default class SetCategoryPopup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tileHeight: 0,
            selectedCategory: window.selectedCat ? window.selectedCat : "Bad 1",
            categories: ["Bad 1", "Bad 2", "Bad 3", "Elternbad", "Gästebad"],
            edit: window.selectedCat ? true : false,
            uuid: window.uuid ? window.uuid : this.uuidv4(),
            imageNote: window.selectedImage && window.selectedCat ? window.selectedImage.note : ""
        }

        this.getColor = this.getColor.bind(this);
        this.refCallback = this.refCallback.bind(this);
    }

    refCallback = element => {
        if (element) {
            console.log("element");
            this.setState({tileHeight: element.getBoundingClientRect().width});
        }
    };

    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    getColor(value) {
        console.log("getColor", this.state.selectedCategory, value)
        if(this.state.selectedCategory == value) {
            return "#CC9759";
        }
        return "#E6E6E6";
    }

    onClickChip(e) {
        this.setState({selectedCategory: e.target.innerHTML})
    }

    isEdit() {
        console.log("isEdit", window, window.selectedCat)
        return window.selectedCat ? true : false;
    }

    onFinish() {
        if(this.state.selectedCategory != window.selectedCat || this.isEdit()) {
            this.props.updateImage(this.state.uuid, this.getImageProperty(), this.state.selectedCategory, this.state.imageNote);
        } else if(this.state.selectedCategory != window.selectedCat) {
            this.props.addImage(window.selectedImage, this.state.selectedCategory,this.state.imageNote);
        }
        //window.selectedCat = null;
        this.props.onClosePopup(this);
    }

    renderChips() {
        var chips = [];
        for(var i =0; i < this.state.categories.length; i++) {
            var category = this.state.categories[i];
            chips.push(
                <div className={""} key={"chip"+ category} style={{margin: 10, display: "flex"}}>
                <Chip onClick={this.onClickChip.bind(this)}selected={this.state.selectedCategory == category} color={this.getColor(category)}text={category}  />
                </div>
            )
        }

        chips.push(<div className={""} key={"chip"+ category} style={{margin: 10}}>

        </div>)
        return (
            <div style={{ display: 'inline-flex', flexWrap: 'wrap'}} className={""}>
                {chips}
             </div>
            )
    }

    getImageProperty = () => {
        console.log("selectedImage", window.selectedImage)
        var item = window.selectedImage;
        if(typeof item != "string") item = item.image;
        return item;
    }

    getImage() {
        console.log("get image",this.isEdit(), window.selectedImage)
        if(this.isEdit()) {
            var item = window.selectedImage;
            if(typeof item != "string") item = item.image;
             return apiAddress.replace("/api", "") + "/pictures/"+  item;
        } else {
            return window.selectedImage;
        }
    }

    render() {
        return(
            <Popup>
                <View>
                    <Page>
                        <Navbar title="Kategorie wählen">
                            <NavRight>
                                <Link onClick={() => this.props.onClosePopup(this)}>Close</Link>
                            </NavRight>
                        </Navbar>
                        <Block >
                            <img ref={this.refCallback}  style={{ width: "100%", maxHeight: window.innerWidth}} src={this.getImage()} />
                            <div  style={{margin: 0,marginTop: 30}}>
                                <input style={{border: "0.5px solid #F19B38", padding: 5, width: "100%"}}  onChange={(e) => this.setState({imageNote: e.target.value})} placeholder={"Notiz"} value={this.state.imageNote} type={"text"} />
                                {this.renderChips()}
                            </div>
                            {/*<div style={{textAlign: "center", marginTop: 50}}>*/}
                                {/*<Chip onClick={this.onFinish.bind(this)} selected={this.state.selectedCategory}color={this.getColor("Fertig")} text={"Fertig"}  />*/}
                            {/*</div>*/}
                        </Block>
                        <Fab onClick={this.onFinish.bind(this)} position="center-bottom" slot="fixed" text="Fertig" color="orange">
                            <Icon ios="f7:add" aurora="f7:add" md="material:add"></Icon>
                        </Fab>
                    </Page>

                </View>

            </Popup>
        )
    }
}