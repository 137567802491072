const serverPort = 80;

const apiAddress = (function () {
    //return "http://localhost:4000/api"
    return "https://pinnwand.dasbad3.de/api"
  let hostname = window.location.hostname.split('.');

  // if a port is used or localhost is the hostname
  const isLocalhost =
    window.location.origin.split(':') > 2 ||
    (hostname.length === 1 && hostname[0] === 'localhost');

  if (isLocalhost) {
    return 'http://'+ hostname.join('.') +':' + serverPort + "/api";
  } else {
    return "https://elements-pinnwand.herokuapp.com/api"
    //return "http://10.50.10.248:3000"
  }

  if (hostname[0] === 'develop') {
    hostname.splice(1, 0, 'api');
  } else {
    hostname.unshift('api');
  }

  return 'https://' + hostname.join('.');
})();

export default apiAddress;
