import Logo from "../../resources/Download.png"

import React from 'react';
import {
    Popup, View,
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    Searchbar,
    PhotoBrowser,
Label, Input, ListButton, BlockFooter
} from 'framework7-react';
import {openNamePopup, openPopup} from "../../actions/PopupActions";
import {goToAbout} from "../../actions/AboutActions";
import HomePage from "./HomePage";
import {goToDefaultRoute} from "../../actions/DefaultRouteActions";
import {openPanelLeft} from "../../actions/PanelLeftActions";
import {openPanelRight} from "../../actions/PanelRightActions";
import {connect} from "react-redux";
import {goToDynamicRoute} from "../../actions/DynamicRouteActions";
import {goToForm} from "../../actions/FormActions";
import {getPinnboardsByHouse} from "../../actions/DashboardActions";
import {openLogin} from "../../actions/LoginActions";
import sampleImage from "../../resources/sampleImage"
import elementWhite from "../../resources/elements_white.png"
import apiAddress from "../../services/api/apiAddress"
const background = "https://www.elements-show.de/sites/default/files/styles/crop_sr_958_height_min/public/media-plus/hg-elements-traumbad-05_19_1.jpg?itok=8VDOwjJV"

const accentColor = "#CC9759";
class CustomerOverview extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tileHeight: 0,
            save: true,
            loginOpen: true,
            email: localStorage.getItem('customer-email'),
            token: localStorage.getItem('customer-token'),
            customers: [
                {
                    name: "Remi Tessier",
                    email: "rete@adrodev.de",
                    pinboardID: "123456789",
                    updatedAt: new Date(),
                    items: [
                        {
                            category: "Bad 1",
                            items: [
                                sampleImage,
                                sampleImage,
                                sampleImage
                            ]
                        }
                    ]
                }
            ],
            filteredCustomer: props.customers
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        try {
            if(this.props.customer.credentials.email && this.props.customer.credentials.token) {
                this.props.getPinnboardsByHouse(true,this.state.email, this.state.token, (success) => {
                    console.log(success)
                    this.setState({loginOpen: false})
                }, (err) => {
                    console.log(err)
                    alert("login failed")
                })
            } else {
                this.setState({loginOpened: true})
            }
        }catch(err) {

        }

    }

    componentDidMount() {
        try {
            if(this.props.customer.credentials.email && this.props.customer.credentials.token) {
                this.props.getPinnboardsByHouse(true,this.state.email, this.state.token, (success) => {
                    console.log(success)
                    this.setState({loginOpen: false})
                }, (err) => {
                    console.log(err)
                    alert("login failed")
                })
            } else {
                this.setState({loginOpened: true})
            }
        }catch(err) {

        }

    }



    onRef = element => {
        if (element && this.state.tileHeight == 0) {
            this.setState({tileHeight: element.getBoundingClientRect().width});
        }
    };

    getCustomerImagePreviewByIndex(customer, index) {
        var images = []
        for(var i = 0; i < customer.items.length; i++) {
            for(var x = 0; x < customer.items[i].items.length; x++) {
                images.push(customer.items[i].items[x]);
                if(images.length == index+1) {
                    var item = customer.items[i].items[x];
                    var image = customer.items[i].items[x];
                    if (item && typeof item !== 'string') {
                        image = item.image;
                    }
                    return apiAddress.replace("/api", "") + "/pictures/" + image;
                }
            }
        }
        return null;

    }

    getFirstFourImagesOfCustomer(customer, index) {
        var images = []
        for(var i = 0; i < customer.items.length; i++) {
            for(var x = 0; x < customer.items[i].items.length; x++) {
                images.push(customer.items[i].items[x]);
                if(images.length == index+1) {
                    var item = customer.items[i].items[x];
                    var image = customer.items[i].items[x];
                    if (item && typeof item !== 'string') {
                        image = item.image;
                    }
                    return image;
                }
            }
        }
        return images;
    }

    onSearch(ev) {
        const {customers} = this.props;
        var filteredCustomer = customers;
        if(ev.target.value.length > 0) {
            filteredCustomer = customers.filter((item) => {
                var labelEqual = false;
                if(item.name) {
                    labelEqual = item.name.toLowerCase().includes(ev.target.value.toLowerCase());
                }
                return labelEqual
            });

            console.log("filtered Devices", filteredCustomer);


        }

        this.setState({filteredCustomer: filteredCustomer, filter: ev.target.value})
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.customers.length != this.props.customers.length) {
            this.setState({filteredCustomer: nextProps.customers});
        }
    }

    onLoginStaffMember() {
        this.props.getPinnboardsByHouse(this.state.save, this.state.email, this.state.token, (success) => {
            console.log(success)
            this.setState({loginOpen: false})
        }, (err) => {
            console.log(err)
            alert("login failed")
        })
    }

    renderListViewItem() {
        const {customers} = this.props;
        console.log("render customer", customers)
        var items = customers;
        if(customers.length != this.state.filteredCustomer.length) {
            items = this.state.filteredCustomer;
        }
        var views = []
        for(var i =0; i < items.length; i++) {
            const customer = items[i];
            views.push(
                <div onClick={() => {window.currentCustomer = customer; this.$f7router.navigate('/customer/'+this.state.pinboardID)}} key={customer.name + "list-element"} style={{display: "flex", padding: 15}}>
                    <div ref={this.onRef} style={{flex: 3,display: "flex", flexDirection: "column", backgroundColor: "transparent", height: this.state.tileHeight}}>
                        <div style={{display: "flex", flex: 1}}>
                        <div style={{flex: 1, margin: 2, backgroundSize: "cover", backgroundImage: "url(" + this.getCustomerImagePreviewByIndex(customer, 0) + ")", backgroundColor: "#B8BBC5"}}></div>
                            <div style={{flex: 1, margin: 2, backgroundSize: "cover", backgroundImage: "url(" + this.getCustomerImagePreviewByIndex(customer, 1) + ")", backgroundColor: "#B8BBC5"}}></div>
                        </div>
                        <div style={{display: "flex", flex: 1}}>
                            <div style={{flex: 1, margin: 2, backgroundSize: "cover", backgroundImage: "url(" + this.getCustomerImagePreviewByIndex(customer, 2) + ")", backgroundColor: "#B8BBC5"}}></div>
                            <div style={{flex: 1, margin: 2, backgroundSize: "cover", backgroundImage: "url(" + this.getCustomerImagePreviewByIndex(customer, 3) + ")", backgroundColor: "#B8BBC5"}}></div>
                        </div>
                    </div>
                    <div style={{flex: 8}}>
                        <div style={{marginLeft: 10, display: "flex", flexDirection: "column"}}>
                        <span style={{fontSize: "1.2em", fontWeight: 500}}> {customer.name}</span>
                        <span style={{color: "#B8BBC5"}}>{this.countCustomerElements(customer)} Produkte</span>
                        </div>
                    </div>
                    <div style={{flex: 1}}>
                        <span style={{fontWeight: 500}}>{customer.updatedAt.toLocaleString()}</span>
                    </div>
                </div>
            )
        }
        return views;
    }

    countCustomerElements(customer) {
        var count = 0;
        for(var i = 0; i < customer.items.length; i++) {
            count = count + customer.items[i].items.length;
        }
        return count;
    }

    onTextFieldChanged(name, value) {
        this.state[name] = value;
        this.setState(this.state);
    }

    onSaveChanged(e) {
        var self = this;
        self.setState({ save: e.target.checked });
    }



    render() {
        return(
            <Page>
                <div style={{textAlign: "center",backgroundColor: "#1A1A1A"}}>
                    <img style={{width: 70, paddingTop: 10, paddingBottom: 10}} src={elementWhite} />
                </div>
                <div style={{backgroundColor: "#F2F2F2", padding: 10}}>
                <Searchbar
                    searchContainer=".search-list"
                    searchIn=".item-title"
                    onChange={this.onSearch.bind(this)}
                    disableButton={!this.$theme.aurora}
                ></Searchbar>
                </div>
                <div style={{height: "100%", backgroundColor: "#F2F2F2"}}>
                    {this.renderListViewItem()}
                </div>

                <Popup opened={this.state.loginOpen}>
                    <View>
                        <Page>
                            <Navbar title="Login">
                                <NavRight>

                                </NavRight>
                            </Navbar>
                            <Block>
                                <List form>
                                    <ListItem>
                                        <div style={{width: "100%", justifyContent: "center", alignCenter: "center", display: "flex"}}>
                                            <img src={Logo} />
                                        </div>
                                    </ListItem>
                                    <ListItem>
                                        <Input
                                            name="email"
                                            placeholder="Email"
                                            type="text"
                                            onChange={({ target }) => this.onTextFieldChanged(target.name, target.value)}
                                            value={this.state.email}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <Input
                                            name="token"
                                            placeholder="Ihr Token"
                                            type="text"
                                            onChange={({ target }) => this.onTextFieldChanged(target.name, target.value)}
                                            value={this.state.token}
                                        />
                                    </ListItem>
                                    <ListItem checked={this.state.save} onChange={(e) => {this.onSaveChanged(e)}} checkbox title="Login Daten merken" name="demo-checkbox" defaultChecked></ListItem>

                                    <ListButton title="Los geht's" onClick={this.onLoginStaffMember.bind(this)}></ListButton>
                                </List>


                            </Block>
                        </Page>
                    </View>
                </Popup>


            </Page>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGoToAbout: () => dispatch(goToAbout()),
        onGoToForm: () => dispatch(goToForm()),
        onGoToDynamicRoute: () => dispatch(goToDynamicRoute()),
        onGoToDefaultRoute: () => dispatch(goToDefaultRoute()),
        onOpenLeftPanel: () => dispatch(openPanelLeft()),
        onOpenRightPanel: () => dispatch(openPanelRight()),
        onOpenPopup: () => dispatch(openPopup()),
        openNamePopup: () => dispatch(openNamePopup()),
        onOpenLoginScreen: () => dispatch(openLogin()),
        getPinnboardsByHouse: (save,email, token, success, error) => {dispatch(getPinnboardsByHouse(save, email, token, success, error))}
    };
};

const mapStateToProps = state => ({
    customers: state.customer.all,
    customer: state.customer

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOverview);
