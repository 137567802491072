
import * as axios from 'axios';
import apiAddress from './apiAddress';

const apiClient = axios.create({
  baseURL: apiAddress,
  timeout: 5000,
  headers: { "Content-Type": "application/json" },
});

export default class Https {
  static get(path) {
    return apiClient({
      method: 'get',
      url: path,
    })
  }

  static post(path, body, token) {
    if(token) {
      Https.setAccessToken(token.accessToken)
    }
    return apiClient({
      method: 'post',
      url: path,
      data: JSON.stringify(body),
    })
  }

  static put(path, body, token) {
    if(token) {
      Https.setAccessToken(token.accessToken)
    }
    return apiClient({
      method: 'put',
      url: path,
      data: JSON.stringify(body),
    })
  }

  static delete(path, body) {
    return apiClient({
      method: 'delete',
      url: path,
      data: JSON.stringify(body),
    });
  }

  static setAccessToken(token) {
    apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

}
