import React from "react";
import {connect} from "react-redux";
import {navigateTo} from "framework7-redux";
import {setCurrentHouse} from "../actions/DashboardActions";
import {
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Toolbar,
    Block,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,
    PhotoBrowser,
    Actions,
    ActionsGroup,
    ActionsLabel,
    ActionsButton,
    Card
} from 'framework7-react';

import elementWhite from "../resources/elements_white.png"

const mapStateToProps = (state) => {
    return {
        currentPinnboardId: state.dashboard.currentPinnboardId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toLogin: () => dispatch(navigateTo("/login/")),
        setCurrentHouse: (name) => dispatch(setCurrentHouse(name)),
        toPinnboard: (pinnboardId) => dispatch(navigateTo("/pinnboard/"+pinnboardId)),
    };
};

class EntryPage extends React.Component {

    componentWillMount() {
        setTimeout(() => {
            if(this.props.currentPinnboardId == null) {

            } else {
                // this.props.toPinnboard(this.props.currentPinnboardId)
                this.$f7router.navigate("/pinnboard/"+this.props.currentPinnboardId);
            }
        }, 0)
    }

    onInit() {



    }

    onHouseSelected(house) {
        this.props.setCurrentHouse(house);
        this.$f7router.navigate("/login/");
    }

    render(){
        return (
            <Page pageInit={this.onInit.bind(this)}>
                <div style={{display: "flex", justifyContent: "center",backgroundColor: "#1A1A1A",paddingTop: 10,paddingBottom: 40, paddingLeft: 10, paddingRight: 10}}>
                    <div style={{width: "100%",textAlign: "center",  display: "flex",color:"white", justifyContent: "center", flexDirection: "column", alignItems:"center"}}>
                        <img style={{width: 70, paddingBottom: 10}} src={elementWhite} />
                        <h4>ELEMENTS Pinnwand</h4>
                        <div style={{position: "relative"}}>
                            {/*<div style={{width: "100%", height: "100%",position: "absolute", top: 0,opacity: 0.5, backgroundSize: "contain",backgroundRepeat: "no-repeat", backgroundImage: "url(" + background + ")" }}> </div>*/}
                            <div>
                                <p>Herzlich willkommen zur ELEMENTS Pinnwand.</p>
                                <p>Bitte wählen Sie das ELEMENTS Studio aus in dem Sie sich befinden.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <BlockTitle>Standorte</BlockTitle>
                    <List simple-list>
                        <ListItem onClick={() => {this.onHouseSelected("Nürtingen")
                        }} title="Nürtingen" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Heidenheim")
                        }} title="Heidenheim" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Rottweil")
                        }} title="Rottweil" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Lindau")
                        }} title="Lindau" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Ingolstadt")
                        }} title="Ingolstadt" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Kempten")
                        }} title="Kempten" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Herford")
                        }} title="Herford" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Osnabrück")
                        }} title="Osnabrück" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Rheine")
                        }} title="Rheine" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Memmingen")
                        }} title="Memmingen" link="#"></ListItem>
                        <ListItem onClick={() => {this.onHouseSelected("Ulm-Nord")
                        }} title="Ulm-Nord" link="#"></ListItem>




                    </List>
                </div>

            </Page>
        )

    }
}

    export default connect(mapStateToProps, mapDispatchToProps)(EntryPage);
