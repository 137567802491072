import { connect } from 'react-redux'

import Popup from '../components/modals/Popup';
import { openPopup, closePopup } from '../actions/PopupActions';
import SetCategoryPopup from "../components/modals/SetCategoryPopup";
import SetNamePopup from "../components/modals/SetNamePopup";
import {shareWithStaff} from "../actions/DashboardActions";

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPopup: (image) => dispatch(openPopup(image)),
        onClosePopup: () => dispatch(closePopup()),
        shareWithStaff: (name, successCB, errorCB) => dispatch(shareWithStaff(name, successCB, errorCB)),
    };
};

export default connect(null, mapDispatchToProps)(SetNamePopup);
