import { connect } from 'react-redux'

import Popup from '../components/modals/Popup';
import { openPopup, closePopup } from '../actions/PopupActions';
import {addImage, shareWithHouse, shareWithStaff, updateImage} from '../actions/DashboardActions';
import SetCategoryPopup from "../components/modals/SetCategoryPopup";
import SetNamePopup from "../components/modals/SetNamePopup";
import {goBack} from "../services/Navigator";


const mapDispatchToProps = (dispatch) => {
  return {
    onOpenPopup: (image) => dispatch(openPopup(image)),
    onClosePopup: (self) => goBack(self),
      addImage: (image, category, note) => dispatch(addImage(image, category, note)),
      updateImage: (uuid, image, category, note) => dispatch(updateImage(uuid, image, category, note)),
      shareWithHouse: () => dispatch(shareWithHouse()),

  };
};

export default connect(null, mapDispatchToProps)(SetCategoryPopup);
