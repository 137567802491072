
import React from 'react';
import {Chip,Button, Popup, View, Page, Navbar, NavRight, Link, Block, BlockTitle, List, ListInput  } from 'framework7-react';
export default class SetNamePopup extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tileHeight: 0,
            name: ""
        }

        this.onFinish = this.onFinish.bind(this);
    }

    onPageBeforeRemove() {
        const self = this;
        self.pickerDevice.destroy();
    }
    onPageInit() {
        const self = this;
        const app = self.$f7;
        // iOS Device picker
        self.pickerDevice = app.picker.create({
            inputEl: '#demo-picker-device',
            cols: [
                {
                    textAlign: 'center',
                    values: ['iPhone 4', 'iPhone 4S', 'iPhone 5', 'iPhone 5S', 'iPhone 6', 'iPhone 6 Plus', 'iPad 2', 'iPad Retina', 'iPad Air', 'iPad mini', 'iPad mini 2', 'iPad mini 3'],
                },
            ],
        });
    }


    onFinish() {
        const app = this.$f7;
        const self = this;
        self.$f7.dialog.preloader('Synchronizing');
        this.props.shareWithStaff(this.state.name, () => {
            self.$f7.dialog.close();
            app.dialog.alert('Success!');
            this.props.onClosePopup();
        }, (error) => {
            app.dialog.alert('Error!');
        })
    }


    render() {
        return(
            <Popup>

                <View>

                    <Page onPageInit={this.onPageInit.bind(this)} onPageBeforeRemove={this.onPageBeforeRemove.bind(this)} >
                        <div style={{display: "flex", flexDirection: "column", height: "100%",backgroundColor:"#1A1A1A"}}>
                        <Navbar title="Teilen">
                            <NavRight>
                                <Link onClick={this.props.onClosePopup}>Close</Link>
                            </NavRight>
                        </Navbar>

                        <Block>
                            <div style={{display: "flex",alignSelf: "center", alignItems: "center", flexDirection: "column", textAlign: "center", marginTop: 50}}>
                                <span style={{color: "white"}}>Bitte geben Sie Ihren Namen ein, dass unser Mitarbeiter Ihre Pinnwand zuordnen kann.</span>
                                <input value={this.state.name} placeholder={"Name eingeben"} onChange={(e) => this.setState({name: e.target.value})} style={{width: "75%",margin: 20,padding: 5, paddingBottom: 5, borderRadius: 5, backgroundColor: "white", color: "#3E404D"}} type={"text"} />
                                <Button onClick={this.onFinish} fill text={"Abesenden"} />
                            </div>
                        </Block>
                    </div>
                    </Page>

                </View>

            </Popup>
        )
    }
}