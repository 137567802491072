import { goBack, navigateTo, showAlert } from 'framework7-redux';
import Https from "../services/api/apiClient";

import { loginValid } from '../selectors/LoginSelectors';

export const openLogin = () => navigateTo('/login/');
export const closeLogin = () => goBack();

export const usernameUpdated = (username) => ({
  type: 'USERNAME_UPDATED',
  payload: username
});

export const passwordUpdated = (password) => ({
  type: 'PASSWORD_UPDATED',
  payload: password
});

export const phoneNumberUpdated = (number) => ({
    type: 'PHONENUMBER_UPDATED',
    payload: number
});


export const login = (email, name, phonenumber, onSuccess, onFailure) => {
    return (dispatch, getState) => {
        // TODO Register in Backend
        Https.post("/auth/register", {email: email, name: name, phoneNumber: phonenumber}).then((res) => {
            if(onSuccess) onSuccess(res.data);

            dispatch({
                type: "REGISTER_SUCCESS",
                pinnboardId: res.data.pinnboardId
            })
            //dispatch(closeLogin());
        }).catch((err) => {
            dispatch(showAlert('Incorrect password! Hint: please enter "password!".', 'Failed Login'));
            if(onFailure) onFailure(err);
        });
    };
};
